<template>
  <div
    class="
      d-flex
      flex-column flex-column-fluid
      bgi-position-y-bottom
      position-x-center
      bgi-no-repeat bgi-size-contain bgi-attachment-fixed
    "
  >
    <video loop="loop" autoplay="" playsinline="" muted="" id="mejs_8752354333970005_html5" preload="none" src="https://www.stillnetwork.com/wp-content/uploads/2022/09/STILLNETWORK-INTRO.mp4" style="margin: 0px; object-fit: cover; height: 100vh;">
				<source type="video/mp4" src="https://www.stillnetwork.com/wp-content/uploads/2022/09/STILLNETWORK-INTRO.mp4">
				
	</video>
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" style="position: absolute;   left: 50%;   top: 50%;   transform: translate(-50%, -50%);">
        <router-view></router-view>
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
</script>
